/* colors */

:root{
  --gray900:#253343;



  --green600:#05B37F;
}
body{
  font-family: 'Roboto';
  overflow-x: hidden;
}


.App {
  text-align: center;
}

.MainMargin {
  margin-left: 18px;
}

.marginRightRisponsive {
  margin-right: 8px;
}

.header-steps {
  font-size: 16px;
  color: var(--gray900);
  font-weight: 400;
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.defaultButton {
  padding: 13px 19px;
  font-size: 16px;
  color: var(--gray900);
  border: 1px solid var(--green600);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
}

.ButtonBlue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 12px 9px 16px;
  font-size: 16px;
  color: white;
  border: 1px solid var(--green600);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 22.5px;
  background-color: #539DF0;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobileInvisible{
  display: block;
}

.headerGreen {
  color: var(--green600);
  font-size: 35px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
}

.responsiveImageHeaderLayout {
  display: flex;
  /* width: 45%; */
}

.responsiveOrderGridLayout{
  order: unset;
}
.pargraphcontent {
  color: #30445B;
  font-size: 18px;
  font-weight: 400;
}

.hiidenScrollBar::-webkit-scrollbar{
  display: none;
}
.footerPadding{
  padding: 18px 216px;
}
.responsiveMarginMentorsSay {
  margin-left: 90px;
  justify-content: start;
}
.responsiveFlexable {
  display: flex;
}
.footerMarginTop{
  margin-top: 0px;
}
@media (max-width:800px) {
  .mobileInvisible{
    display: block;
  }
  .footerPadding{
    padding: 18px 32px;
  } 
}
.compareBakImage {
  top: -90px;
}
@media (max-width:1024px) {
  .responsiveImageHeaderLayout {
    display: grid;
    /* width: 100%; */
  }  
  .compareBakImage {
  top: -200px;
  }
  .footerMarginTop{
    margin-top: 12px;
  }  
  .responsiveFlexable {
  display: block;
  }
  .responsiveOrderGridLayout{
  order: 2;
  } 
  .responsiveMarginMentorsSay {
    margin-left: 0px;
    justify-content: center;
  }  
}

@media (min-width: 1024px) {
  .marginRightRisponsive{
    margin-right: 95px;
  }
  .MainMargin {
    margin-left: 103px;
  }
}

.InutFieldClass:focus{
  outline: none;
}
